import hop from './hop'
import globals from './getGlobals'
import { Hash } from '../types/Hash'
import { Json, JsonData, JsonVal } from '../types/JsonData'
import { getDocParams } from './queryStr'
import SecurityLogInterceptor from './SecurityLogFilter'
import is from './is'

const loggerCache = {}
type LogEnum = 0 | 1 | 2 | 3 | 4

export interface ILogInterceptor {
  key: string
  run: (name: string, ...args: any) => JsonData[] | string[] // returns true if handled and stops the output. False will continue output.
}

export type LogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR'

export const LOG_LEVEL: { [key: string]: LogEnum } = {
  OFF: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4
}
const enabled: { [logger: string]: LogEnum } = {}
const stringify: { [logger: string]: boolean } = {}
const tabsRx = /^(\t+)/
const window: any = globals.getGlobal<Window>()
const navigator: Navigator = window.navigator

export type ILogTheme = string[]
let logInterceptorCount = 0
export const LOG_INTERCEPTORS: Hash<ILogInterceptor> = {}
const LOG_THEMES: { [key: string]: ILogTheme } = {
  black: ['color:#000000', 'color:#000000', 'color:#000000', 'color:#000000'],
  grey: [
    'color:#999999',
    'color:#999999',
    'color:#666666;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  red: [
    'color:#CD9B9B',
    'color:#CD5C5C',
    'color:#CC3232;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  green: [
    'color:#9CBA7F',
    'color:#78AB46',
    'color:#45B000;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  teal: [
    'color:#B4CDCD;',
    'color:#79CDCD;',
    'color:#37B6CE;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  blue: [
    'color:#42A5F5;',
    'color:#2196F3;',
    'color:#1E88E5;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  purple: [
    'color:#BDA0CB',
    'color:#1976D2',
    'color:#7D26CD;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  orange: [
    'color:#EDCB62',
    'color:#FFAA00',
    'color:#FF8800;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  redOrange: [
    'color:#FF7640',
    'color:#FF4900',
    'color:#BF5930;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  pink: [
    'color:#ff94c2',
    'color:#f06292',
    'color:#e91e63;font-style:italic;font-weight:bold;',
    'color:#FF0000;font-weight:bold;'
  ],
  block: [
    'background-color:#333;color:#FFF;padding:2px;border-radius:5px;',
    'background-color:#333;color:#B4CDCD;padding:2px;border-radius:5px;',
    'background-color:#333;color:#EDCB62;padding:2px;border-radius:5px;',
    'background-color:#333;color:#FF7640;padding:2px;border-radius:5px;'
  ]
}

const loggersMap: { [key: string]: Log } = {}
const eventsMap: { [key: string]: string } = {}
const csl: { log: (any) => void } = window?.console

interface LogState {
  log?: {
    enable?: Hash<LogEnum | string>
    stringify?: Hash<boolean | string>
  }
}

const outNames: { [key: number]: string } = Object.keys(LOG_LEVEL)
let loggedVersion = false
const logVersion = () => {
  if (!loggedVersion) {
    loggedVersion = true
    csl?.log(
      `version ${process.env.NEXT_PUBLIC_NAME || process.env.REACT_PUBLIC_NAME} ${
        process.env.NEXT_PUBLIC_VERSION || process.env.REACT_PUBLIC_VERSION
      }`
    )
  }
}
const setEnablers = (enable?: Hash<LogEnum | string>): void => {
  if (!enable) {
    return
  }
  for (const i in enable) {
    if (hop(enable, i) && hop(enabled, i)) {
      const v = parseInt(enable[i] as string) as LogEnum
      if (enabled[i] !== v) {
        logVersion()
        enabled[i] = v
        csl?.log(`Log.enabled.${i} set to ${outNames[enabled[i] || 0]}`)
      }
    }
  }
}
const setStringifiers = (stringifiers?: Hash<boolean | string>): void => {
  if (!stringifiers) {
    return
  }
  for (const i in stringifiers) {
    if (hop(stringifiers, i)) {
      logVersion()
      const v = (stringifiers[i] === 'true' ? true : stringifiers[i] === 'false' ? false : stringifiers[i]) as boolean
      if (stringify[i] !== v) {
        stringify[i] = v
        csl?.log(`Log.stringify.${i} set to ${stringify[i] ? 'true' : 'false'}`)
      }
    }
  }
}

const types: Hash<LogLevel> = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR'
}

let exposeIntv = 0
const expose = () => {
  const result = {}
  for (const i in loggerCache) {
    if (hop(loggerCache, i)) {
      result[i] = enabled[i] || LOG_LEVEL.OFF
    }
  }
  csl?.log(result)
  return result
}

export type LogArgs = [
  LogLevel | { log_level: LogLevel; message: string; data: JsonData } | JsonData | JsonVal,
  string | JsonData | JsonVal,
  JsonData | JsonVal
]
class Log {
  static types = types
  static enabled = enabled
  static level = LOG_LEVEL
  static stringify = stringify
  static themes = LOG_THEMES
  static setState = (state: LogState): void => {
    if (state) {
      setEnablers(state.log?.enable)
      setStringifiers(state.log?.stringify)
      clearTimeout(exposeIntv)
      exposeIntv = (setTimeout(expose) as unknown) as number
    }
  }
  name
  theme
  styling = false
  static enable = (name: string, level: LogEnum): void => {
    enabled[name] = level
  }

  constructor(name: string, theme: ILogTheme) {
    this.name = name
    this.theme = theme
    this.styling = this.isChrome()
    enabled[name] = enabled[name] || process.env.NODE_ENV === 'development' ? LOG_LEVEL.WARN : LOG_LEVEL.OFF
    loggersMap[name] = this
  }

  isFireFox() {
    return /Firefox/.test(navigator?.userAgent)
  }

  isChrome() {
    return /Chrome/.test(navigator?.userAgent)
  }

  out(...args: (LogArgs | Json)[]) {
    if (!enabled[this.name]) {
      return args
    }
    const type = LOG_LEVEL[args[0] as string] ? args[0] : (args[0] as any)?.log_level || types.DEBUG
    if (LOG_LEVEL[type] < enabled[this.name]) {
      return args
    }

    if (args[0] === types.ERROR) {
      args.push(new Error().stack as string)
    }

    let filteredArgs = [...args]
    if (logInterceptorCount) {
      for (const i in LOG_INTERCEPTORS) {
        if (hop(LOG_INTERCEPTORS, i)) {
          filteredArgs = LOG_INTERCEPTORS[i].run(this.name, ...args)
        }
      }
    }
    // eslint-disable-next-line no-undef
    if (csl?.log && filteredArgs?.length) {
      const pre = this.name + ':'
      if (this.styling && this.isChrome()) {
        let a0 = (filteredArgs[0] as any)?.message || filteredArgs[0]
        if (is.string(a0)) {
          for (let a = 1; a < filteredArgs.length; a += 1) {
            if (stringify[this.name] && typeof filteredArgs[a] === 'object') {
              filteredArgs[a] = JSON.stringify(filteredArgs[a], null, 2)
            }
            if (is.string(filteredArgs[a])) {
              a0 += `:${filteredArgs.splice(a, 1)}`
              a -= 1 // we dropped an index, so we are at the same one.
            } else {
              break
            }
          }
          a0 = `%c${a0?.charAt && a0.charAt(0) === '\t' ? a0.replace(tabsRx, '$1' + pre) : pre + a0}`
          if ((filteredArgs[0] as any)?.message) {
            filteredArgs[1] = filteredArgs[0]
          }
          filteredArgs[0] = a0
          filteredArgs.splice(1, 0, this.theme[LOG_LEVEL[type]])
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      csl?.log(...filteredArgs)
      return filteredArgs
    }
    return filteredArgs
  }
}

export const registerLogger = (name: string, theme: ILogTheme, events: { [key: string]: string }) => {
  loggersMap[name] || new Log(name, theme)
  for (const i in events) {
    if (hop(events, i)) {
      const event = events[i]
      if (eventsMap[event]) {
        throw new Error('Duplicate Event Name')
      } else {
        eventsMap[event] = name // point event to the logger.
      }
    }
  }
}

export const registerLogInterceptor = (li: ILogInterceptor): void => {
  if (hop(LOG_INTERCEPTORS, li.key)) {
    throw new Error(`Log Interceptor ${li.key} is already registered.`)
  }
  LOG_INTERCEPTORS[li.key] = li
  logInterceptorCount += 1
}

const defaultLogger = new Log('_', Log.themes.grey)

const urlParams = getDocParams()
export type LogFn = (...args: LogArgs) => void
export interface Logger {
  log: LogFn
  info: LogFn
  warn: LogFn
  error: LogFn
}
//TODO: add stack trace
const makeLogFn = (name: string, l: Log): Logger => {
  Log.setState(urlParams?.state as LogState)
  return (loggerCache[name] = {
    log: (...args: Json[]) => l.out(Log.types.DEBUG, ...args),
    info: (...args: Json[]) => l.out(Log.types.INFO, ...args),
    warn: (...args: Json[]) => l.out(Log.types.WARN, ...args),
    error: (...args: Json[]) => l.out(Log.types.ERROR, ...args)
  })
}
export const getLogger = (name: string, theme?: string[]): Logger => {
  if (loggerCache[name]) {
    return loggerCache[name]
  }
  const l = loggersMap[name] || (name ? new Log(name, theme || Log.themes.green) : defaultLogger)
  return makeLogFn(name, l)
}
//TODO: remove
export const getLoggerByEvent = (event: string): Logger => {
  const name = eventsMap[event]
  if (!name) {
    throw new Error(`Unregistered Event ${event}. Use registerLogger(name, theme, events)`)
  }
  return getLogger(name)
}

// this is required. If you use logger, it needs to use the security filter. Do not remove.
registerLogInterceptor(SecurityLogInterceptor)

export default Log
