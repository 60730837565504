import hop from './hop'
import is from './is'
import Log, { getLogger } from './Log'
import workIt from './workIt'

const RECURSION_LIMIT = 1000

const log = getLogger('extend', Log.themes.red)
Log.enable('extend', 1) // force logging

export type IExtendOptions = {
  concat?: boolean
  arrayAsObject?: boolean
  objectAsArray?: boolean
  override?: boolean
  deleteNull?: boolean
  clean?: boolean
  targetOnlyProperties?: boolean
  depth?: number
  maxDepth?: number
  maxLimitLogged?: boolean
  filter?: (val: any, key: string) => boolean
}

const tgtOk = (options: IExtendOptions, target, key): boolean => {
  return !options.targetOnlyProperties || hop(target, key)
}

const defaultOptions: IExtendOptions = {
  concat: false,
  arrayAsObject: false,
  objectAsArray: false,
  override: true,
  deleteNull: false,
  clean: false,
  targetOnlyProperties: false,
  depth: 0, // updated for current depth
  maxDepth: 0, // 0 means infinity, maximum depth to avoid recursion or deep cloning.
  maxLimitLogged: false,
  filter: (val, key) => true
}

type Extended<T, K, J> = T & K & J

const getOptionsCopy = (self: IExtendOptions, defaultOptions: IExtendOptions, depth: number): IExtendOptions => {
  return assignOptions(self, defaultOptions, depth)
}

const assignOptions = (target: IExtendOptions, source: IExtendOptions, depth?: number): IExtendOptions => {
  target.concat = target.concat || source.concat
  target.arrayAsObject = target.arrayAsObject || source.arrayAsObject
  target.objectAsArray = target.objectAsArray || source.objectAsArray
  target.override = target.override || source.override
  target.deleteNull = target.deleteNull || source.deleteNull
  target.clean = target.clean || source.clean // clean up undefined properties by removing them.
  target.targetOnlyProperties = target.targetOnlyProperties || source.targetOnlyProperties // only include properties that exist on the target
  target.depth = depth || target.depth || source.depth || 0 // current depth. This gets updated. It should not be set externally
  target.maxDepth = target.maxDepth || source.maxDepth || 0 // the limit of the recursion
  target.filter = target.filter || source.filter
  return target
}

// example
// This allows you to make a deep copy of an object and to set flags as well as pass unlimited extend objects.
// extend.call({objectsAsArray:true}, ob1, obj2);
//
/**
 * Perform a deep extend. SEE extend_Spec.js for examples of options.
 * @param {Object} target
 * @param {Object=} source
 * return {Object|destination}
 */
const extend = function <T, K, J>(target: T, source: K, ...args: J[]): Extended<T, K, J> {
  // THIS MUST BE A FUNCTION and not an arrow function. Or you cannot pass this with params because arrow functions bind the this
  if (is.window(source)) {
    throw Error('Unable to extend Window! You may have a recursive reference.')
  }
  if ((source as unknown as T) === target) {
    return target as Extended<T, K, J> // they are identical. Just return the reference.
  }
  let i = 1
  const myArgs = [target, source, ...args]
  const len = myArgs.length
  let j
  const self = (this || {}) as IExtendOptions
  assignOptions(self, defaultOptions)
  let myCopy
  if (!target && source && typeof source === 'object') {
    target = {} as Extended<T, K, J> // handle if target is undefined, but there is data to extend.
  }
  while (i < len) {
    const item = myArgs[i]
    for (j in item as any) {
      if (hop(item, j) && tgtOk(self, target, j)) {
        if (self.filter && !self.filter(item[j], j)) {
          continue // skipping this property
        }
        if (self.maxDepth && self.depth > self.maxDepth) {
          if (!self.maxLimitLogged && self.depth >= RECURSION_LIMIT) {
            log.warn(`WARNING: HIGH RECURSION in at depth ${self.depth}`)
            self.maxLimitLogged = true
          }
          target[j] = item[j] // depth exceeded. pass by reference
        } else if (is.file(item[j])) {
          target[j] = item[j] // Files must be passed by reference
        } else if (is.date(item[j])) {
          target[j] = new Date(item[j].getTime())
        } else if (is.regex(item[j])) {
          target[j] = new RegExp(item[j])
        } else if (j === 'length' && target instanceof Array) {
          // do not set array length property.
        } else if (target[j] && typeof target[j] === 'object' && !(item[j] instanceof Array)) {
          target[j] = extend.call(getOptionsCopy(self, defaultOptions, self.depth + 1), target[j], item[j])
        } else if (is.array(item[j])) {
          // by putting them all in copy. Wea are able to also convert it to an object as well as concat.
          myCopy = self?.concat ? (target[j] || []).concat(item[j]) : item[j]
          if (self?.arrayAsObject) {
            if (!target[j]) {
              target[j] = { length: myCopy.length }
            }
            if (target[j] instanceof Array) {
              target[j] = extend.call(getOptionsCopy(self, defaultOptions, self.depth + 1), {}, target[j])
            }
          } else {
            target[j] = target[j] || []
          }
          if (myCopy.length) {
            target[j] = extend.call(getOptionsCopy(self, defaultOptions, self.depth + 1), target[j], myCopy)
          }
        } else if (item[j] && typeof item[j] === 'object') {
          if (self.objectAsArray && typeof item[j].length === 'number') {
            if (!(target[j] instanceof Array)) {
              target[j] = extend.call(getOptionsCopy(self, defaultOptions, self.depth + 1), [], target[j])
            }
          }
          target[j] = extend.call(
            getOptionsCopy(self, defaultOptions, self.depth + 1),
            target[j] && typeof target[j] === 'object' ? target[j] : {},
            item[j]
          )
        } else if (self.override !== false || target[j] === undefined) {
          if (self.deleteNull && item[j] === null) {
            delete target[j]
          } else if (self.clean && (item[j] === null || item[j] === undefined)) {
            delete target[j]
          } else {
            if (typeof target != 'object') {
              return item[j]
            } else {
              target[j] = item[j]
            }
          }
        }
      }
    }
    i += 1
  }
  return target as Extended<T, K, J>
}

export const extendWorker = (target, source) => {
  let str = `const extendWorker = (e) => {
      ${'const hop = ' + hop.toString()}
      ${extend.toString()}
      this.postMessage(extend(e.data.target, e.data.source))
  }`
  const parts = str.split('\n')
  parts.map((part, index) => {
    parts[index] = part.replace(/Object\(_([A-Za-z]+)__.*?\)/g, '$1').replace(/Object\(.*?\)\((.*?)\)/, 'typeof $1')
  })
  str = parts.join('\n')
  return workIt({ target, source }, str)
}

export default extend
