import { JsonData } from '../types/JsonData'
import hop from './hop'
import { ILogInterceptor } from './Log'
import keyObj, { alphabet, numeric } from './keyObj'
export const SECURITY_LOG_FILTER = 'security-log-filter'

const MAX_LEN = 20
const ENDS = 5
const keyIt = (str) => keyObj(str.split(''), 1)
const lc = keyIt(alphabet)
const uc = keyIt(alphabet.toUpperCase())
const sp = { '-': 1, _: 1 }
const wordBreak = /\s/g
const filterString = (str) => {
  if (str.length < MAX_LEN) {
    return str
  }
  let a = 0
  let spc = 0
  let nc = 0
  let m = 0
  const parts = str.split(wordBreak)
  for (let p = 0; p < parts.length; p += 1) {
    for (let i = 0; parts[p].length > MAX_LEN && i < parts[p].length; i += 1) {
      const char = parts[p][i]
      a += lc[char] || uc[char] || 0
      spc += sp[char] || 0
      nc += numeric[char] || 0
      m += !alphabet[char] && !numeric[char] ? 1 : 0
      // all done. check
      const len = a + spc + nc
      if ((m || i === parts[p].length - 1) && a && nc && len >= MAX_LEN) {
        const s = i > len ? i - len : 0
        parts[p] = `${parts[p].substr(s, s + ENDS)}...${parts[p].substr(i - ENDS, str.length - len)}`
        i -= len - ENDS * 2 - 3
      }
      if (m) {
        // any unsupported characters. reset counters.
        a = spc = nc = m = 0
      }
    }
  }
  return parts.join(' ')
}

const filterArgs = (args: any): any => {
  const type = typeof args
  if (type === 'string') {
    return filterString(args as string)
  }
  if (args instanceof Array) {
    for (let i = 0; i < args.length; i += 1) {
      args[i] = filterArgs(args[i])
    }
    return args
  }
  if (type === 'object') {
    for (const i in args as JsonData) {
      if (hop(args, i)) {
        args[i] = filterArgs(args[i])
      }
    }
  }
  return args
}

const SecurityLogInterceptor: ILogInterceptor = {
  key: SECURITY_LOG_FILTER,
  /**
   * @param name the name of the module logging.
   * @param args the args passed to the log function.
   * @returns boolean - true if it should stop the log process, and false if it should keep going.
   */
  run(name: string, ...args: any): JsonData[] | string[] {
    return [name, ...filterArgs(args)]
  }
}

export default SecurityLogInterceptor
