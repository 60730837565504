import { JsonData } from '../../shared/src/types/JsonData'
import { Hash } from '../../shared/src/types/Hash'
export interface CourtColor {
  value: string
  label: string
  stroke: string
}

export const CourtColorsHash: Hash<JsonData> = {
  none: {
    value: 'transparent',
    label: 'None',
    stroke: '#000'
  },
  no: {
    value: 'transparent',
    label: 'None',
    stroke: 'transparent'
  },
  wl: {
    value: 'transparent',
    label: 'None',
    stroke: '#fff'
  },
  sb: {
    value: '#004b85',
    label: 'Stadium-Blue',
    stroke: '#fff'
  },
  cg: {
    value: '#71c597',
    label: 'Classic-Green',
    stroke: '#fff'
  },
  br: {
    value: '#4d412b',
    label: 'Brown',
    stroke: '#fff'
  },
  sd: {
    value: '#ce8f3b',
    label: 'Sand-Dune',
    stroke: '#fff'
  },
  by: {
    value: '#6b301e',
    label: 'Burgundy',
    stroke: '#fff'
  },
  tc: {
    value: '#d23f27',
    label: 'Terra-Cotta',
    stroke: '#fff'
  },
  ng: {
    value: '#00461c',
    label: 'Nova-Green',
    stroke: '#fff'
  },
  bl: {
    value: '#009ddc',
    label: 'Blue',
    stroke: '#fff'
  },
  gg: {
    value: '#23772d',
    label: 'Grass-Green',
    stroke: '#fff'
  },
  rd: {
    value: '#bd3019',
    label: 'Red',
    stroke: '#fff'
  },
  gy: {
    value: '#91999f',
    label: 'Grey',
    stroke: '#fff'
  },
  cv: {
    value: '#574f77',
    label: 'Canadian-Violet',
    stroke: '#fff'
  }
}

export default Object.values(CourtColorsHash)
