const isNum = /^d?\.?\d?$/
const cast = (value: string): string | number | boolean => {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  if (isNum.test(value)) {
    return parseFloat(value)
  }
  return value
}

export default cast
