import hop from './hop'

/**
 * Example:
     var object = { 'a': [{ 'b': { 'c': 3 } }] };
     pathResolve(object, 'a[0].b.c');// 3
     pathResolve(object, ['a', '0', 'b', 'c']);// 3
     pathResolve(object, 'a.b.c', 'default'); // object
     pathResolve(object, 'a.b.c'); // "default"
 */
const aryIndexRx = /\[(.*?)\]/g

const pathToArray = (source: any, path: string | Array<string>, data?: any, delimiter?: string): Array<string> => {
  if (path instanceof Array) {
    return path
  }
  delimiter = delimiter || '.'
  path = path || ''
  if (!path.replace) {
    throw new Error(`${path} must be a string`)
  }
  path = path.replace(aryIndexRx, function (m, g1) {
    // eslint-disable-next-line quotes
    if (g1.indexOf(`"`) !== -1 || g1.indexOf(`'`) !== -1) {
      return delimiter + g1
    }
    // disable eslint for this line. These recursive functions need to reference each other.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return delimiter + pathResolve(source, g1, data, delimiter)
  })
  return path.split(delimiter)
}

const pathResolve = (source: any, path: string | string[], data?: any | undefined, delimiter?: string) => {
  const parts: Array<string> = path instanceof Array ? path.slice() : pathToArray(source, path, data, delimiter)
  let s: any = source
  for (let i = 0; i < parts.length; i += 1) {
    const prop = parts[i]
    const has = hop(s, prop)
    if (has || data !== undefined) {
      if (i <= parts.length - 2) {
        if (!has) {
          const v = parseInt(parts[i + 1])
          s[prop] = isNaN(v) ? {} : []
        }
        s = s[prop]
      } else {
        if (data) {
          s[prop] = data // write if data is passed.
        }
        return s[prop]
      }
    } else {
      return s[prop] // the property is not defined. return undefined.
    }
  }
  return
}

export default pathResolve
