import { Hash } from '../types/Hash'
import each, { eachFn } from './each'
import is from './is'

export interface KeyObjPayload {
  value: any
  result: Hash<any>
}
const eachKey: eachFn = (key: string, i: number, p: string[], payload: KeyObjPayload) => {
  payload.result[key] = is.func(payload.value) ? payload.value(key, payload) : payload.value || i
}
const keyObj = (arr: string[], value: any, result?: Hash<any>): Hash<any> => {
  result = result || {}
  each(arr, eachKey, { value, result })
  return result
}
export const alphabet = 'abcdefghijklmnopqrstuvwxyz'
export const numbers = '0123456789'.split('')
export const numeric = keyObj(numbers, 1)
export const decimalistic = keyObj([...numbers, '.'], 1)

let letters
export const isAlpha = (char: string): boolean => {
  letters = letters || keyObj((alphabet + alphabet.toUpperCase()).split(''), 1)
  return !!letters[char]
}

export default keyObj
