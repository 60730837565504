


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { getAngle, getDistance, getPointOnCircle, IRect, Point2D } from '../../shared/src/helpers/geom'
import extend from '../../shared/src/helpers/extend'

interface PointR extends Point2D {
  r?: number
}

interface MLine {
  label?: string
  start: PointR
  end: Point2D
}

const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

class MLineItem implements MLine {
  _label?: string
  trans: IRect
  start: PointR
  end: Point2D
  offset: number
  txo: number

  constructor(trans: IRect, m: MLine, offset: number) {
    this._label = m.label
    this.trans = trans
    this.start = m.start
    this.end = m.end
    this.offset = offset
    this.txo = offset * 0.5
  }

  get label(): string {
    if (this._label) {
      return this._label
    }
    const d = this.distance
    const partial = d % 1
    return `${Math.floor(d)}'${partial ? ` ${Math.round(partial * 12)}"` : ''}`
  }

  get horizontal(): boolean {
    const s = this.start
    const e = this.end
    if (!e) {
      return false
    }
    return Math.abs(s.x - e.x) > Math.abs(s.y - e.y)
  }

  get angle(): number {
    const s = this.start
    const e = this.end
    if (!e) {
      return 0
    }
    return getAngle(s.x, s.y, e.x, e.y)
  }

  get distance(): number {
    const s = this.start
    const e = this.end
    if (!e) {
      return 0
    }
    return getDistance(s.x, s.y, e.x, e.y)
  }

  get center(): Point2D {
    if (!this.end) {
      return this.start
    }
    return getPointOnCircle(this.start.x, this.start.y, this.distance * 0.5, this.angle)
  }

  get tx(): Point2D {
    const c = this.center
    const t = this.trans
    const tx: Point2D = extend({}, c) as Point2D
    // determine vertical or horizontal
    if (this.horizontal) {
      tx.y = c.y < t.y + t.height * 0.5 ? c.y - this.offset : c.y + this.offset
    } else {
      tx.x = c.x < t.x + t.width * 0.5 ? c.x - this.offset : c.x + this.offset
    }
    return tx
  }

  get path(): string {
    const s = this.start
    const e = this.end
    const t = this.trans
    const thw = this.trans.width * 0.5
    const thh = this.trans.height * 0.5
    if (!e) {
      return ''
    }
    const perpendicularAngle = this.angle + Math.PI * 0.5
    const l1s = getPointOnCircle(s.x, s.y, this.txo, perpendicularAngle)
    const l1e = getPointOnCircle(s.x, s.y, this.txo, perpendicularAngle - Math.PI)
    const l2s = getPointOnCircle(e.x, e.y, this.txo, perpendicularAngle)
    const l2e = getPointOnCircle(e.x, e.y, this.txo, perpendicularAngle - Math.PI)
    return `M${s.x < thw && e.x < thw || s.y > thh && e.y > thh ? `${s.x},${s.y} L${l1e.x},${l1e.y}` : `${s.x},${s.y} L${l1s.x},${l1s.y}` } L${s.x},${s.y} L${e.x},${e.y}${s.x > thw && e.x > thw ? ` L${l2s.x},${l2s.y}` : ` L${l2e.x},${l2e.y}`}`
  }
}

@Component
export default class SvgMeasureLines extends Vue {
  @Prop({ default: 'text-mn' }) size!: 'text-ty' | 'text-tn' | 'text-mn'
  @Prop({ default: 2 }) offset!: number
  @Prop({ default: [] }) lines!: MLine[]
  @Prop({
    default: () => {
      return { x: 0, y: 0, w: 0, h: 0 }
    }
  })
  trans!: IRect
  @Prop({ default: lineStyle }) lineStyle!: string
  @Prop({ default: textStyle }) textStyle!: string

  get lineItems(): MLineItem[] {
    return this.lines.map((m: MLine) => {
      return new MLineItem(this.trans, m, this.offset)
    })
  }
}
