/**
 * The purpose of this method is to be able to iterate over objects similar to a map but being able
 * to pass object parameters in without creating a function. More reuse of methods because of the
 * parameters instead of closures
 */
import { Hash } from '../types/Hash'
import { JsonData, JsonVal } from '../types/JsonData'
import hop from './hop'
export type eachFn = (item: any, index?: any, parent?: any, ...args: any) => void | any

// function* gen() {}

const each = (list: any[] | JsonData | JsonVal | Hash<any>, fn: eachFn, ...args): any => {
  if (list instanceof Array) {
    for (let i = 0; i < list.length; i += 1) {
      const v = fn(list[i], i, list, ...args)
      if (v !== undefined) {
        return v
      }
    }
  } else if (list) {
    for (const i in list as any) {
      if (hop(list, i)) {
        const v = fn(list[i], i, list, ...args)
        if (v !== undefined) {
          return v
        }
      }
    }
  }
  return list
}

//TODO: asyncEach using generators.

export default each
