import browser from './browser'
import cast from './cast'
import pathResolve from './pathResolve'
import { JsonData } from '../types/JsonData'

export const toQueryStr = function (params: JsonData): string {
  if (typeof params === 'string') {
    return params
  }
  let str = ''
  for (const i in params) {
    if (Object.prototype.hasOwnProperty.call(params, i)) {
      str += `${str.length ? '&' : ''}${i}=${
        typeof params[i] === 'object' ? encodeURIComponent(JSON.stringify(params[i])) : params[i]
      }`
    }
  }
  return str
}
export const fromQueryStr = (str: string): JsonData => {
  const query = str.indexOf('?') !== -1 ? str.split('?').pop() : str
  const parts = query?.split('&') || []
  const store = {}
  for (let i = 0; i < parts.length; i += 1) {
    let [key, value] = parts[i].split('=')
    key = decodeURIComponent(key)
    value = decodeURIComponent(value)
    if (value.indexOf('{') === 0) {
      try {
        const json = JSON.parse(value)
        value = json
      } catch (e) {
        // it isn't valid json. So leave it alone.
      }
    }
    if (key && value !== undefined) {
      pathResolve(store, key, cast(value))
    }
  }
  return store
}
let lastParams = {}
const lastParamsTime = 0
export const getDocParams = (): JsonData => {
  if (browser()) {
    // throttle so multiple calls don't cause this to parse again.
    if (lastParamsTime < Date.now() - 100) {
      lastParams = fromQueryStr(globalThis.document?.location?.href?.split('?')[1] || '') || {}
    }
    return lastParams
  }
  return {}
}
