import { JsonData, JsonVal } from '..'
import browser from './browser'
import hop from './hop'

const typeOf = (obj: any): string => {
  if (obj == null) {
    return (obj + '').toLowerCase()
  } // implicit toString() conversion

  const deepType = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
  if (deepType === 'generatorfunction') {
    return 'function'
  }

  // Prevent overspecificity (for example, [object HTMLDivElement], etc).
  // Account for functionish Regexp (Android <=2.3), functionish <object> element (Chrome <=57, Firefox <=52), etc.
  // String.prototype.match is universally supported.

  return deepType.match(/^(array|bigint|date|error|function|generator|regexp|symbol)$/)
    ? deepType
    : typeof obj === 'object' || typeof obj === 'function'
    ? 'object'
    : typeof obj
}

const is = {
  window: (obj: any): boolean => !!(obj?.document && obj.location && obj.alert && obj.setInterval),
  array: (obj: any): boolean => obj instanceof Array,
  date: (obj: any): boolean => obj instanceof Date,
  regex: (obj: any): boolean => obj instanceof RegExp,
  file: (obj: any): boolean => browser() && obj instanceof File,
  func: (obj: any): boolean => typeOf(obj) === 'function',
  obj: (obj: any): boolean => typeof obj === 'object',
  string: (obj: any): boolean => typeof obj === 'string',
  number: (obj: any): boolean => typeof obj === 'number',
  bool: (obj: any): boolean => typeof obj === 'boolean',
  filterMatch: (obj: any): boolean => obj?.$c !== undefined,
  promise: (obj: any): boolean => Boolean(obj.then && obj.catch && is.func(obj.then)),
  empty: (val: any): boolean => {
    if (val === null) {
      // diff returns null when they are empty.
      return true
    }
    if (is.string(val)) {
      return val === ''
    }
    if (is.array(val)) {
      return val.length === 0
    }
    if (is.obj(val)) {
      for (const e in val) {
        if (hop(val, e)) {
          return false
        }
      }
      return true
    }
    return false
  },
  typeOf,
  equal: (src: JsonVal | JsonData, target: JsonVal | JsonData, deep?: boolean): boolean => {
    let i, s, t
    if (typeof src === 'string' || typeof src === 'number' || typeof src === 'boolean') {
      return src === target
    }
    if (src === target) {
      return true
    }
    if (src === undefined) {
      return false
    }
    const srcKeys = Object.keys((src as unknown) as JsonData)
    const targetKeys = Object.keys((target as unknown) as JsonData)
    const srcLen = srcKeys.length
    const targetLen = targetKeys.length

    if (srcLen !== targetLen) {
      //console.log("different keys ", srcLen - targetLen);
      return false
    }
    if (deep) {
      for (i = 0; i < srcLen; i += 1) {
        s = src[srcKeys[i]]
        t = src[targetKeys[i]]
        if (typeof s === 'object' && t && !is.equal(src[srcKeys[i]], target[srcKeys[i]], deep)) {
          // compare as objects.
          return false
        }
      }
    }

    return true
  }
}

export default is
