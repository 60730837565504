import inRange from '../common/inRange'
import { decimalistic, isAlpha } from './keyObj'

export interface Point2D {
  x: number
  y: number
}

export type SvgLineType = 'M' | 'Q' | 'L' | 'C'

export interface PointSVG extends Point2D {
  type: SvgLineType
  str: string
}

export interface Point3D extends Point2D {
  z: number
}

export interface BoxSize2D {
  width: number
  height: number
}

export interface IRect extends Point2D, BoxSize2D {}

export const getDistance = (x1: number, y1: number, x2: number, y2: number): number => {
  return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2)
}

export const degreesToRadians = (deg: number): number => deg * (Math.PI / 180)

export const radiansToDegrees = (radians: number): number => radians * (180 / Math.PI)

export const getAngle = (x1: number, y1: number, x2: number, y2: number): number => {
  return Math.atan2(y2 - y1, x2 - x1) // in radians
}

export const calcPolygonArea = (vertices: Point2D[]): number => {
  let total = 0

  for (let i = 0, l = vertices.length; i < l; i++) {
    const addX = vertices[i].x
    const addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y
    const subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x
    const subY = vertices[i].y

    total += addX * addY * 0.5
    total -= subX * subY * 0.5
  }

  return Math.abs(total)
}

export const getCenterOfRect = (rect: IRect): Point2D => {
  return {
    x: rect.x + rect.width * 0.5,
    y: rect.y + rect.height * 0.5
  }
}

export const getDistanceToRect = (rect: IRect, pt: Point2D): number => {
  const cx = Math.max(Math.min(pt.x, rect.x + rect.width), rect.x)
  const cy = Math.max(Math.min(pt.y, rect.y + rect.height), rect.y)
  return Math.sqrt((pt.x - cx) * (pt.x - cx) + (pt.y - cy) * (pt.y - cy))
}

export const getPointOnCircle = (cx: number, cy: number, r: number, a: number): Point2D => {
  return { x: cx + r * Math.cos(a), y: cy + r * Math.sin(a) }
}

export const getPointOnRect = (rect: IRect, angle: number): Point2D => {
  const radius = Math.min(rect.width, rect.height) * 0.5
  const c = getCenterOfRect(rect)
  const pt = getPointOnCircle(c.x, c.y, radius, angle)
  const left = Math.abs(rect.x - pt.x)
  const top = Math.abs(rect.y - pt.y)
  const right = Math.abs(rect.x + rect.width - pt.x)
  const bottom = Math.abs(rect.y + rect.height - pt.y)
  const min = Math.min(left, top, right, bottom)
  if (min === left) {
    pt.x = rect.x
  } else if (min === top) {
    pt.y = rect.y
  } else if (min === right) {
    pt.x = rect.x + rect.width
  } else if (min === bottom) {
    pt.y = rect.y + rect.height
  }
  return pt
}

export const isSmoothPoint = (points: Point2D[], point: Point2D, smoothRange: number): boolean => {
  if (points.length < 2) {
    return true
  } else {
    const i = points.length
    const prev1 = points[i - 1]
    const prev2 = points[i - 2]
    const prevA = getAngle(prev2.x, prev2.y, prev1.x, prev1.y)
    const currentA = getAngle(prev1.x, prev1.y, point.x, point.y)
    if (!inRange(currentA, prevA, smoothRange)) {
      return true
    }
  }
  return false
}

export const smoothPoints = (points: Point2D[], smoothRange: number): Point2D[] => {
  const result = []
  for (let i = 0; i < points.length; i += 1) {
    const p = points[i]
    if (isSmoothPoint(points, p, smoothRange)) {
      points.push(p)
    }
  }
  return result
}

const parsePointSvgStr = (p: PointSVG) => {
  // now map out the points
  let s = '',
    x = false
  for (let n = 0; n < p.str.length; n += 1) {
    let c = p.str[n]
    if (decimalistic[c]) {
      s += c
      c = ''
    }
    if (s && (c || n === p.str.length - 1)) {
      if (!x) {
        x = true
        p.x = parseFloat(s)
      } else {
        p.y = parseFloat(s)
      }
      s = ''
    }
  }
}

export const pathToPoints = (path: string): PointSVG[] => {
  const sp: PointSVG[] = []
  let p: PointSVG = { type: 'M', x: 0, y: 0, str: '' }
  for (let i = 0; i < path.length; i += 1) {
    const c = path[i]
    if (isAlpha(c)) {
      if (p.str) {
        parsePointSvgStr(p as PointSVG)
      }
      p = { type: c as SvgLineType, x: 0, y: 0, str: c }
      sp.push(p)
    } else if (p) {
      p.str += c
    }
  }
  parsePointSvgStr(p)
  return sp
}

export const addPointToPath = (path: string, point: PointSVG): string => {
  return path + (path[path.length - 1] === ' ' ? '' : ' ') + `${point.type}${point.x},${point.y}`
}

export const pointsToSvgPath = (points: (Point2D | PointSVG)[]): string => {
  let str = `M${points[0].x},${points[0].y}`
  for (let i = 1; i < points.length; i += 1) {
    const p = points[i]
    str += ` ${(p as PointSVG).type || 'L'}${p.x},${p.y}`
  }
  return str
}
