/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */

const fallbackGlobalObject = {}

/**
 * Checks whether we're in the Node.js or Browser enviroment
 *
 * @returns Answer to given question
 */
export function isNodeEnv(): boolean {
  return Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]'
}

/**
 * Safely get global scope object
 *
 * @returns Global scope object
 */
export function getGlobal<T>(): T {
  return (isNodeEnv()
    ? global
    : typeof window !== 'undefined'
    ? window
    : typeof self !== 'undefined'
    ? self
    : fallbackGlobalObject) as T
}

export default {
  getGlobal,
  isNodeEnv
}
